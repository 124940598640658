<template>
    <parent-layout title="Data Explorer">
        <v-tabs>
            <v-tab
                v-for="(item, index) in items"
                :key="index"
                :to="item.route"
                :disabled="disabled(item)"
                link
            >
                {{ item.title }}
            </v-tab>
        </v-tabs>
        <router-view />
    </parent-layout>
</template>

<script>
import ParentLayout from '@/components/layout/ParentLayout'
import router from '@/router'
import { mapGetters } from 'vuex'

export default {
    components: {
        ParentLayout,
    },
    data: () => ({
        tab: null,
        items: [
            { route: { name: 'import-logs' }, title: 'Import Logs' },
            { route: { name: 'export-logs' }, title: 'Export Logs' },
            { route: { name: 'table-viewer' }, title: 'Table Viewer' },
        ],
    }),
    computed: {
        ...mapGetters({ hasRole: 'auth/hasRole' }),
    },
    methods: {
        disabled(item) {
            const resolvedRoute = router.resolve({ name: item.route.name })
            const securityRoles = resolvedRoute?.route?.meta?.securityRoles

            return securityRoles ? !this.hasRole(securityRoles) : false
        },
    },
}
</script>
